@use "../../scss/variables.scss" as vars;

.editImageContainer {
  height: 200px;
  width: 200px;
  border-radius: 50%;
  position: relative;
  // border:1px solid white;
  .imageContainer {
    height: 100%;
    width: 100%;
    border-radius: 50%;
    z-index: 1;
    overflow: hidden;

    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
      border: 1px solid black;
      border-radius: 50%;
    }
  }
  .imageContainerEmpty {
    height: 100%;
    width: 100%;
    border-radius: 50%;
    z-index: 1;
    overflow: hidden;
    border: 2px dashed vars.$color-dark_grey_50;
    .inputImageContainer {
      background-color: #f2f2f2;
      border-radius: 50%;
    }
    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }

  .updateBtn {
    position: absolute;
    z-index: 5;
    height: 50px;
    width: 50px;
    bottom: 10px;
    right: 10px;
    border-radius: 50%;
    background-color: white;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  .removeImg {
    position: absolute;
    z-index: 5;
    height: 30px;
    width: 30px;
    top: 15px;
    right: 15px;
    border-radius: 50%;
    background-color: rgba(255, 0, 0, 1);
    color: white;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
}

.roundedDiv {
  border-radius: 20px;
  border: 1px solid black;
  padding: 1.5rem;
  background-color: #f2f2f2;
  .editBtn {
    background-color: black;
    color: white;
    height: 40px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    // width: 90px;
    img {
      height: 20px;
    }
  }
  input {
    height: 40px;
    border-radius: 20px;
    border: none;
    outline: none;
  }
}
.loading {
  border: 8px solid white;
  border-top: 8px solid white;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.logoContainer {
  height: 130px;
  position: relative;
  overflow: hidden;
  border-radius: 20px;
  img {
    height: 100%;
    width: 100%;
    object-fit: contain;
  }

  .removeLogo {
    position: absolute;
    z-index: 5;
    height: 30px;
    width: 30px;
    top: 5px;
    right: 5px;
    border-radius: 50%;
    background-color: rgba(220, 0, 0, 1);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  .inputLogoContainer {
    border: 2px dashed vars.$color-dark_grey_50;
    border-radius: 20px;
  }
}

.socialRow {
  height: 30px;
  text-decoration: underline;
  img {
    height: 20px;
  }
}

.webinterfaceImageContainer {
  height: 200px;

  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}
.webInterfaceBtn {
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid black;
  background-color: white;
  border-radius: 20px;
}
.updateContactsBtn {
  height: 40px;
  background-color: white;
  border: 1px solid black;
  border-radius: 20px;
  &:hover {
    transform: scale(1.1);
    transition-duration: 300ms;
  }
}
