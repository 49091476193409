.headerDiv {

    border-bottom: 3px solid black;

  .logoContainer {
    // border: 1px solid red;
    img {
      max-height: 30px;
      max-width: 100%;
    }
  }
}
