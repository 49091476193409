@use "../../scss/variables.scss" as vars;

.detailsBtn {
  height: 30px;
  background-color: black;
  border-radius: 20px;
  &:hover {
    transform: scale(1.1);
    transition-duration: 300ms;
  }
}
